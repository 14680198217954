import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
// Use CommonJS require instead of ES modules
const OpenAI = require('openai').OpenAI;

// ABI for ERC20 token balance checking
const ERC20_ABI = [
  "function balanceOf(address owner) view returns (uint256)",
  "function decimals() view returns (uint8)"
];

const TokenGatedDalle = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);
  const [walletLoading, setWalletLoading] = useState(false);
  const [walletError, setWalletError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [account, setAccount] = useState('');

  // DALL-E states
  const [prompt, setPrompt] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const TOKEN_ADDRESS = '0x2A2Df09D835f6aB0BC91EaBC94C5832875844036';
  const REQUIRED_BALANCE = ethers.utils.parseUnits('10000000', 18); // 10 Million tokens

  // Initialize OpenAI client
  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY,
    dangerouslyAllowBrowser: true
  });

  const checkBalance = async (address) => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const tokenContract = new ethers.Contract(TOKEN_ADDRESS, ERC20_ABI, provider);
      
      const balance = await tokenContract.balanceOf(address);
      const decimals = await tokenContract.decimals();
      
      console.log('Balance:', ethers.utils.formatUnits(balance, decimals));
      return balance.gte(REQUIRED_BALANCE);
    } catch (err) {
      console.error('Error checking balance:', err);
      return false;
    }
  };

  const connectWallet = async () => {
    if (typeof window.ethereum === 'undefined') {
      setWalletError('Please install MetaMask!');
      return;
    }

    setWalletLoading(true);
    setWalletError('');

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send('eth_requestAccounts', []);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      
      setAccount(address);
      setIsConnected(true);
      
      const hasEnoughTokens = await checkBalance(address);
      setHasAccess(hasEnoughTokens);
      
      if (!hasEnoughTokens) {
        setWalletError('Insufficient Anarchy token balance. 10M tokens required.');
      }
    } catch (err) {
      console.error('Connection error:', err);
      setWalletError('Failed to connect wallet');
    } finally {
      setWalletLoading(false);
    }
  };

  const generateImage = async () => {
    if (!prompt.trim()) {
      setError('Please enter a prompt');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await openai.images.generate({
        model: "dall-e-3",
        prompt: prompt,
        n: 1,
        size: "1024x1024"
      });

      setImageUrl(response.data[0].url);
    } catch (err) {
      console.error('Error:', err);
      setError(err.message || 'Failed to generate image');
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    if (!imageUrl) return;

    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `dalle-${Date.now()}.png`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      setError('Failed to download image');
      console.error('Download error:', err);
    }
  };

  // Connection Modal
  const Modal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-900 p-8 rounded-lg max-w-md w-full mx-4">
        <h2 className="text-xl font-bold text-red-500 mb-4">Connect Wallet</h2>
        <p className="text-gray-300 mb-6">
          Connect your wallet and verify your Anarchy token balance to access the DALL-E image generator.
          Required balance: 10M ANARCHY
        </p>
        
        {walletError && (
          <div className="text-red-500 text-sm mb-4">{walletError}</div>
        )}
        
        <button
          onClick={connectWallet}
          disabled={walletLoading}
          className="w-full px-6 py-3 bg-red-500 text-white rounded-lg hover:bg-red-600 disabled:opacity-50"
        >
          {walletLoading ? 'Connecting...' : 'Connect Wallet'}
        </button>

        {account && (
          <div className="mt-4 text-sm text-gray-400 break-all">
            Connected: {account}
          </div>
        )}
      </div>
    </div>
  );

  useEffect(() => {
    if (!isConnected || hasAccess) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  }, [isConnected, hasAccess]);

  return (
    <div className="min-h-screen bg-black text-gray-100">
      <div className="max-w-2xl mx-auto p-6">
        <h1 className="text-2xl font-bold text-center mb-8 text-red-500">DALL-E Image Generator</h1>
        
        {!isConnected ? (
          <div className="text-center">
            <button
              onClick={() => setShowModal(true)}
              className="px-6 py-3 bg-red-500 text-white rounded-lg hover:bg-red-600"
            >
              Connect Wallet to Access
            </button>
          </div>
        ) : hasAccess ? (
          <div className="space-y-6">
            <div className="space-y-4">
              <textarea
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="Enter your image prompt here..."
                className="w-full h-32 p-4 bg-gray-900 border border-red-900 rounded-lg text-white focus:outline-none focus:border-red-500"
              />
              
              {error && (
                <div className="text-red-500 text-sm">{error}</div>
              )}

              <div className="flex justify-center">
                <button
                  onClick={generateImage}
                  disabled={loading}
                  className="px-6 py-3 bg-red-500 text-white rounded-lg hover:bg-red-600 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {loading ? 'Generating...' : 'Generate Image'}
                </button>
              </div>
            </div>

            {imageUrl && (
              <div className="space-y-4">
                <div className="relative">
                  <img
                    src={imageUrl}
                    alt="Generated by DALL-E"
                    className="w-full rounded-lg shadow-lg"
                  />
                  <button
                    onClick={handleDownload}
                    className="absolute top-4 right-4 p-2 bg-white rounded-full shadow-lg hover:bg-gray-100 text-gray-900"
                    title="Download Image"
                  >
                    Download
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="text-center text-red-500">
            Insufficient Anarchy token balance. 10M tokens required.
          </div>
        )}

        {showModal && <Modal />}
      </div>
    </div>
  );
};

export default TokenGatedDalle;