import React from 'react';
import { useQuery } from '@airstack/airstack-react';
import { Link } from 'react-router-dom';

const ANARCHY_QUERY = `
  query MyQuery {
    FarcasterCasts(
      input: {filter: {rootParentUrl: {_eq: "https://warpcast.com/~/channel/anarchy"}}, blockchain: ALL, limit: 10}
    ) {
      Cast {
        rawText
        embeds
        castedBy {
          profileName
          profileImage
          connectedAddresses {
            address
            blockchain
          }
        }
        fid
        numberOfLikes
        numberOfRecasts
        numberOfReplies
        castValue {
          formattedValue
        }
      }
    }
  }
`;

const Proposals = () => {
  const { data, loading, error } = useQuery(ANARCHY_QUERY);

  if (loading) {
    return (
      <div className="min-h-screen bg-black text-gray-100 pt-24">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex items-center justify-center">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-red-500"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-black text-gray-100 pt-24">
        <div className="max-w-6xl mx-auto px-4">
          <div className="bg-red-900/20 p-6 rounded-lg">
            <p className="text-red-500">Error loading casts: {error.message}</p>
          </div>
        </div>
      </div>
    );
  }

  const casts = data?.FarcasterCasts?.Cast || [];

  return (
    <div className="min-h-screen bg-black text-gray-100">
      {/* Navigation */}
      <nav className="fixed w-full bg-black/90 backdrop-blur-sm border-b border-red-900 z-50">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            <Link to="/" className="text-red-500 font-bold text-2xl">ANARCHY</Link>
          </div>
        </div>
      </nav>

      {/* Main Content */}
      <div className="pt-24">
        <div className="max-w-6xl mx-auto px-4">
          <h1 className="text-4xl font-bold mb-8 text-red-500">Proposals from Anarchy Channel</h1>
          
          <div className="overflow-x-auto">
            <table className="w-full border-collapse">
              <thead>
                <tr className="border-b border-red-900">
                  <th className="py-4 px-6 text-left">Author</th>
                  <th className="py-4 px-6 text-left">Message</th>
                  <th className="py-4 px-6 text-center">Likes</th>
                  <th className="py-4 px-6 text-center">Recasts</th>
                  <th className="py-4 px-6 text-center">Replies</th>
                </tr>
              </thead>
              <tbody>
                {casts.map((cast, index) => (
                  <tr 
                    key={`${cast.fid}-${index}`}
                    className="border-b border-red-900/30 hover:bg-red-900/10"
                  >
                    <td className="py-4 px-6">
                      <div className="flex items-center space-x-3">
                        {cast.castedBy.profileImage && (
                          <img 
                            src={cast.castedBy.profileImage} 
                            alt={cast.castedBy.profileName}
                            className="w-8 h-8 rounded-full"
                          />
                        )}
                        <div>
                          <div className="font-medium">{cast.castedBy.profileName}</div>
                          <div className="text-sm text-gray-400">
                            {cast.castedBy.connectedAddresses[0]?.address.slice(0, 6)}...
                            {cast.castedBy.connectedAddresses[0]?.address.slice(-4)}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="py-4 px-6">{cast.rawText}</td>
                    <td className="py-4 px-6 text-center">{cast.numberOfLikes}</td>
                    <td className="py-4 px-6 text-center">{cast.numberOfRecasts}</td>
                    <td className="py-4 px-6 text-center">{cast.numberOfReplies}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Proposals;