import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const AnarchyCoin = () => {
  const [deviceType, setDeviceType] = useState('desktop');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [priceData, setPriceData] = useState({
    price: '0.00',
    marketCap: '0',
    volume: '0'
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setDeviceType('mobile');
      } else if (window.innerWidth <= 1024) {
        setDeviceType('tablet');
      } else {
        setDeviceType('desktop');
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchPriceData = async () => {
      try {
        // Fetch token price
        const priceResponse = await fetch('https://api.geckoterminal.com/api/v2/simple/networks/base/token_price/0x2A2Df09D835f6aB0BC91EaBC94C5832875844036');
        const priceData = await priceResponse.json();
        const price = priceData.data.attributes.token_prices['0x2a2df09d835f6ab0bc91eabc94c5832875844036'];
  
        // Fetch market cap and volume
        const metricsResponse = await fetch('https://api.geckoterminal.com/api/v2/networks/base/pools/0xBf372E8FBFB6841E75BD94c04A59fA99629Ec390');
        const metricsData = await metricsResponse.json();
        
        setPriceData({
          price: Number(price).toFixed(12),
          marketCap: Number(metricsData.data.attributes.fdv_usd).toLocaleString(),
          volume: Number(metricsData.data.attributes.volume_usd.h24).toLocaleString()
        });
      } catch (error) {
        console.error('Error fetching price data:', error);
      }
    };
  
    fetchPriceData();
    const interval = setInterval(fetchPriceData, 30000);
    return () => clearInterval(interval);
  }, []);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setIsMenuOpen(false);
    }
  };

  // Mobile-specific styles
  const mobileStyles = {
    heroContainer: "relative pt-16 px-4",
    heroImage: "relative w-full h-auto mx-auto",
    heroContent: "relative py-4 text-center",
    metricsGrid: "grid grid-cols-1 gap-4",
    featuresGrid: "grid grid-cols-1 gap-4",
    aboutLayout: "flex flex-col space-y-4",
    aboutText: "w-full",
    aboutImage: "w-full h-auto mt-4",
  };

  // Desktop styles
  const desktopStyles = {
    heroContainer: "relative pt-16",
    heroImage: "relative w-[1024px] h-[866px] mx-auto",
    heroContent: "absolute bottom-8 right-8 text-right",
    metricsGrid: "grid grid-cols-3 gap-8",
    featuresGrid: "grid md:grid-cols-2 gap-8",
    aboutLayout: "relative flex items-center",
    aboutText: "w-1/2 space-y-4 relative z-10",
    aboutImage: "absolute right-0 top-1/2 -translate-y-1/2 w-[500px]",
  };

  // Choose styles based on device type
  const styles = deviceType === 'mobile' ? mobileStyles : desktopStyles;

  return (
    <div className="min-h-screen bg-black text-gray-100">
      {/* Navigation */}
      <nav className="fixed w-full bg-black/90 backdrop-blur-sm border-b border-red-900 z-50">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex justify-between items-center h-16">
            <div className="text-red-500 font-bold text-2xl">ANARCHY</div>
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-300 hover:text-red-500 transition-colors"
            >
              <svg 
                className="h-6 w-6" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Menu Overlay */}
        {isMenuOpen && (
  <div className="absolute top-16 left-0 w-full bg-black/95 border-b border-red-900">
    <div className="max-w-6xl mx-auto px-4 py-4">
      <div className="flex flex-col space-y-4">
        <button
          onClick={() => scrollToSection('about')}
          className="text-left text-gray-300 hover:text-red-500 transition-colors py-2"
        >
          About
        </button>
        <button
          onClick={() => scrollToSection('features')}
          className="text-left text-gray-300 hover:text-red-500 transition-colors py-2"
        >
          Features
        </button>
        <button
          onClick={() => scrollToSection('roadmap')}
          className="text-left text-gray-300 hover:text-red-500 transition-colors py-2"
        >
          Roadmap
        </button>
        <button
          onClick={() => scrollToSection('proposals')}
          className="text-left text-gray-300 hover:text-red-500 transition-colors py-2"
        >
          Proposals
        </button>
        <Link
          to="/imggen"
          className="text-left text-gray-300 hover:text-red-500 transition-colors py-2"
          onClick={() => setIsMenuOpen(false)}
        >
          DALL-E Generator
        </Link>
      </div>
    </div>
  </div>
)}
      </nav>

      {/* Hero Section */}
      <div className={styles.heroContainer}>
        <div className={styles.heroImage}>
          <img 
            src="/main.png" 
            alt="Anarchy Background" 
            className="w-full h-full object-contain"
          />
          <div className={styles.heroContent}>
            <p className="text-white text-xl mb-4 drop-shadow-lg">
              Decentralize building, join the revolution.
            </p>
            <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-3 px-8 rounded-full 
                             transform transition hover:scale-105">
              <a 
                href="https://app.uniswap.org/swap?outputCurrency=0x2A2Df09D835f6aB0BC91EaBC94C5832875844036&chain=base"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-red-500 hover:bg-red-600 text-white font-bold py-3 px-8 rounded-full 
                          transform transition hover:scale-105"
              >
                Buy Now
              </a>
            </button>
          </div>
        </div>
      </div>

      {/* Metrics Bar */}
      <div className="bg-black/90 border-y border-red-900">
        <div className="max-w-6xl mx-auto px-4 py-6">
          <div className={styles.metricsGrid}>
            <div className="text-center p-4">
              <div className="text-gray-400 mb-1">Price</div>
              <div className="text-2xl font-bold text-red-500">${priceData.price}</div>
            </div>
            <div className="text-center p-4">
              <div className="text-gray-400 mb-1">Market Cap</div>
              <div className="text-2xl font-bold text-red-500">${priceData.marketCap}</div>
            </div>
            <div className="text-center p-4">
              <div className="text-gray-400 mb-1">24H Volume</div>
              <div className="text-2xl font-bold text-red-500">${priceData.volume}</div>
            </div>
          </div>
        </div>
      </div>

      {/* About Section */}
      <section id="about" className="bg-black py-24">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-4xl font-bold mb-8 text-red-500">About</h2>
          <div className={styles.aboutLayout}>
            <div className={styles.aboutText}>
              <p className="text-gray-300 text-lg">
                ANARCHY is more than just a token - it's a movement. Built on the principles of 
                true decentralization and community governance, we're here to challenge the 
                status quo of traditional finance.
              </p>
              <p className="text-gray-300 text-lg">
                With innovative tokenomics and a passionate community, ANARCHY represents the future 
                of decentralized currency.
              </p>
              <p className="text-gray-300 text-lg">
                We build what the community wants, by proposal.
              </p>
            </div>
            <div className={deviceType === 'mobile' ? "mt-8" : styles.aboutImage}>
              <img 
                src="/army.png" 
                alt="Army Background" 
                className="w-full h-auto"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="bg-black/90 py-24">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-4xl font-bold mb-8 text-red-500">Features</h2>
          <div className={styles.featuresGrid}>
            <div className="bg-red-900/20 p-6 rounded-lg">
              <h3 className="text-2xl font-bold mb-4 text-red-500">Core Features</h3>
              <ul className="space-y-2 text-gray-300 text-lg">
                <li>• 100% Community Owned</li>
                <li>• Zero Team Tokens</li>
                <li>• Locked Liquidity</li>
                <li>• Deflationary Mechanics</li>
              </ul>
            </div>
            <div className="bg-red-900/20 p-6 rounded-lg">
              <h3 className="text-2xl font-bold mb-4">Supply</h3>
              <p className="text-4xl font-bold text-red-500">100B</p>
              <p className="text-gray-300">Total Supply</p>
            </div>
          </div>
        </div>
      </section>

      {/* Roadmap Section */}
      <section id="roadmap" className="bg-black py-24">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-4xl font-bold mb-8 text-red-500">Roadmap</h2>
          <div className="space-y-8">
            <div className="border-l-2 border-red-500 pl-4">
              <h3 className="text-2xl font-bold mb-2">Phase 1</h3>
              <p className="text-gray-300 text-lg">Token Launch & Community Building</p>
            </div>
            <div className="border-l-2 border-red-500 pl-4">
              <h3 className="text-2xl font-bold mb-2">Phase 2</h3>
              <p className="text-gray-300 text-lg">CEX Listings & Marketing Push</p>
            </div>
            <div className="border-l-2 border-red-500 pl-4">
              <h3 className="text-2xl font-bold mb-2">Phase 3</h3>
              <p className="text-gray-300 text-lg">DAO Implementation & Governance</p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-black border-t border-red-900 py-8">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex flex-col items-center space-y-6">
            {/* Social Links */}
            <div className="flex space-x-6">
              <a
                href="https://twitter.com/0x_anarchy"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-red-500 transition-colors"
              >
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                </svg>
              </a>
              <a
                href="https://t.me/+n4lDvqTvysdlMmEx"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-red-500 transition-colors"
              >
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M11.944 0A12 12 0 0 0 0 12a12 12 0 0 0 12 12 12 12 0 0 0 12-12A12 12 0 0 0 12 0a12 12 0 0 0-.056 0zm4.962 7.224c.1-.002.321.023.465.14a.506.506 0 0 1 .171.325c.016.093.036.306.02.472-.18 1.898-.962 6.502-1.36 8.627-.168.9-.499 1.201-.82 1.23-.696.065-1.225-.46-1.9-.902-1.056-.693-1.653-1.124-2.678-1.8-1.185-.78-.417-1.21.258-1.91.177-.184 3.247-2.977 3.307-3.23.007-.032.014-.15-.056-.212s-.174-.041-.249-.024c-.106.024-1.793 1.14-5.061 3.345-.48.33-.913.49-1.302.48-.428-.008-1.252-.241-1.865-.44-.752-.245-1.349-.374-1.297-.789.027-.216.325-.437.893-.663 3.498-1.524 5.83-2.529 6.998-3.014 3.332-1.386 4.025-1.627 4.476-1.635z"/>
                </svg>
              </a>
              <a
                href="https://www.dextools.io/app/en/base/pair-explorer/0xbf372e8fbfb6841e75bd94c04a59fa99629ec390"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-red-500 transition-colors"
              >
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 14.5v-9l7.5 4.5-7.5 4.5z"/>
                </svg>
              </a>
              <a
                href="https://warpcast.com/~/channel/anarchy"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-red-500 transition-colors"
              >
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z"/>
                </svg>
              </a>
              <a
                href="https://etherscan.io/token/0x2A2Df09D835f6aB0BC91EaBC94C5832875844036"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-red-500 transition-colors"
              >
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-15.5l-5 7.5h5v4l5-7.5h-5v-4z"/>
                </svg>
              </a>
            </div>
            <p className="text-gray-500">© 2024 ANARCHY Coin. Join the revolution.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AnarchyCoin;